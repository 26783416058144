import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ThemeToggle from "./themeToggle"

const Header = ({ siteTitle }) => (
  <header className="border-b border-accent ">
    <nav className="flex flex-wrap items-center justify-between max-w-4xl p-1 px-4 mx-auto">
      <Link to="/" className="flex items-center flex-shrink-0">
        <svg
          className="mr-1 h-7 fill-accent"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            xmlns="http://www.w3.org/2000/svg"
            points="200,186.6 150,100 100,13.4 50,100 0,186.6 61.87,170.3 54.93,186.56 81.08,186.56 93.37,136.89 
							49.63,150.17 74.04,107.89 98.44,65.62 122.85,107.89 147.25,150.17 103.78,136.65 116.27,186.56 142.41,186.56 135.05,169.49 "
          />
        </svg>
        <h1 className="mb-0 text-3xl font-semibold">{siteTitle}</h1>
      </Link>
      <button
        data-collapse-toggle="mobile-menu"
        type="button"
        className="inline-flex items-center p-2 ml-3 text-sm rounded-lg primary-text hover:text-accent md:hidden focus:outline-none focus:ring-2 focus:ring-accent"
        aria-controls="mobile-menu-2"
        aria-expanded="false"
        onClick={() => {
          const menu = document.getElementById("mobile-menu")
          menu.classList.toggle("hidden")
        }}
      >
        <span className="sr-only">Abrir menu</span>
        <svg
          className="w-6 h-6"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clipRule="evenodd"
          ></path>
        </svg>
        <svg
          className="hidden w-6 h-6"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>

      <div
        className="hidden w-full ml-auto md:block md:w-auto"
        id="mobile-menu"
      >
        <ul className="flex flex-col gap-1 m-0 my-2 list-none md:flex-row md:space-x-8 md:my-0 md:text-sm md:font-medium">
          <li>
            <Link to="/">Inicio</Link>
          </li>
          <li>
            <Link to="/utils">Utils</Link>
          </li>
          <li>
            <Link to="/contact">Contacto</Link>
          </li>
          <li className="flex items-center">
            <ThemeToggle />
          </li>
        </ul>
      </div>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
