/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import { Footer } from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {/* 
			min-h-screen: makes div cover whole screen, without it screen will flash when changing theme
			flex-auto: helps to center content vertically
			*/}
      <div className="flex-col justify-between flex-auto min-h-screen px-0 m-0 transition-all duration-200 bg-primary text-main-text">
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className="max-w-4xl mx-auto my-0">
          <main className="m-4">{children}</main>
          <Footer />
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
